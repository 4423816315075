@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h2 {
    @apply text-4xl;
  }
  p {
    @apply text-justify;
  }
  .marker {
    li::marker {
      @apply text-primary;
    }
  }
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
